/* .btn {
    padding: 8px 16px;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin-right: 8px;
    margin-bottom: 8px;
  } */
  .btn-orange {
    background-color: #fd7e14;
    color: #fff;
    border: none;
  }
  .bg-orange {
    background-color: #fd7e14; 
    border: none;
  }
  
  .btn-orange:hover {
    background-color: #fd7e14; /* Mouse over hone par same color rahe */
    color: #fff; /* Text ka color white */
  }
  
  .btn-outline-orange {
    background-color: transparent;
    color: #fd7e14;
    border: 1px solid #fd7e14;
  }
  
  .btn-outline-orange:hover {
    background-color: #fd7e14; /* Mouse over hone par background orange ho jaye */
    color: #fff; /* Text ka color white ho jaye */
  }
  .btn-yellow {
    background-color: #FFDF00;
    color: #fff;
    border: none;
  }
  
  .btn-yellow:hover {
    background-color: #FFDF00; /* Mouse over hone par same color rahe */
    color: #fff; /* Text ka color white */
  }
  
  .btn-outline-yellow {
    background-color: transparent;
    color: #FFDF00;
    border: 1px solid #FFDF00;
  }
  
  .btn-outline-yellow:hover {
    background-color: #FFDF00; /* Mouse over hone par background orange ho jaye */
    color: #fff; /* Text ka color white ho jaye */
  }
  

  .btn-blue {
    background-color: #1569c7;
    color: #fff;
    border: none;
  }
  
  .btn-outline-blue {
    background-color: transparent;
    color: #1569c7;
    border: 1px solid #1569c7;
  }
  .btn-green {
    background-color: #3CB043;
    color: #fff;
    border: none;
  }
  
  .btn-outline-green {
    background-color: transparent;
    color: #3CB043;
    border: 1px solid #3CB043;
  }
  .btn-purple {
    background-color: #A32CC4;
    color: #fff;
    border: none;
  }
  
  .btn-outline-purple {
    background-color: transparent;
    color: #A32CC4;
    border: 1px solid #A32CC4;
  }
  .btn-pink {
    background-color: #FF1694;
    color: #fff;
    border: none;
  }
  
  .btn-outline-pink {
    background-color: transparent;
    color: #FF1694;
    border: 1px solid #FF1694;
  }
  .btn-red {
    background-color: #E3242B;
    color: #fff;
    border: none;
  }
  
  .btn-outline-red {
    background-color: transparent;
    color: #E3242B;
    border: 1px solid #E3242B;
  }
  .btn-gold {
    background-color: #D4Af37;
    color: #fff;
    border: none;
  }
  
  .btn-outline-gold {
    background-color: transparent;
    color: #D4Af37;
    border: 1px solid #D4Af37;
  }
  .btn-white {
    background-color: #ffffff;
    color: #fff;
    border: none;
  }
  
  .btn-outline-white {
    background-color: transparent;
    color: #ffffff;
    border: 1px solid #ffffff;
  }
  .btn-black {
    background-color: #000000;
    color: #fff;
    border: none;
  }
  
  .btn-outline-black {
    background-color: transparent;
    color: #000000;
    border: 1px solid #000000;
  }
  .btn-maroon {
    background-color: #5D100A;
    color: #fff;
    border: none;
  }
  
  .btn-outline-maroon {
    background-color: transparent;
    color: #5D100A;
    border: 1px solid #5D100A;
  }

 
